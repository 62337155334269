import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ClockIcon from "../../../assets/icons/clock.svg";
import ConfirmIcon from "../../../assets/icons/modal-confirm.svg";
import StarIcon from "../../../assets/icons/star.svg";
import TickCardIcon from "../../../assets/icons/tick-card.svg";
import CallIcon from "../../../assets/icons/user-call.svg";
import EmailIcon from "../../../assets/icons/user-email.svg";
import { SendReview } from "../../../controller/services/common";
import { GetHiredNurseDetails } from "../../../controller/services/hospital/hired-nurse";
import { CompleteJob } from "../../../controller/services/hospital/jobs";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
import { setLoading } from "../../../redux/slices/flags_slice";
import { getFormattedValue, getShiftNames } from "../../../utils/helper";
import CommonModal from "../../common/modals/commonModal";
import RatingModal from "../../common/modals/ratingModal";
import StyledChip from "../../common/styled/chip";
import { RatingsAndReviewsListHos } from "../job-module/active-jobs/viewActiveJob";

export default function ViewHiredNurse() {
  const [nurseData, setNurseData] = useState(null);
  const [jobData, setCurrentJobData] = useState(null);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [showRatings, setShowRatings] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    fetchHiredNurseDetails();
  }, []);
  useEffect(() => {
    if (nurseData?.jobData?.length > 0) {
      setCurrentJobData(nurseData?.jobData[0]);
      setSelectedJobTitle(nurseData?.jobData[0]?.title);
    }
  }, [nurseData?.jobData?.length]);
  const fetchHiredNurseDetails = () => {
    dispatch(setLoading(true));
    GetHiredNurseDetails(params.id)
      .then((resp) => {
        setNurseData(resp.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleCompleteJob = () => {
    dispatch(setLoading(true));
    CompleteJob(jobData?.applicantDetails?._id)
      .then((resp) => {
        setCompleteModalVisible(false);
        toast.success(resp.message);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleJobChange = (e) => {
    console.log(e.target.value, "fsdfdsf");
    const foundJob = nurseData?.jobData?.find(
      (i) => i.title === e.target.value
    );
    setSelectedJobTitle(foundJob?.title);
    setCurrentJobData(foundJob);
  };
  console.log(selectedJobTitle, "selectedJobTitle");
  const handleReviewSubmit = (values) => {
    const payload = {
      userId: nurseData?.userData?._id,
      jobId: jobData?._id,
      rating: values?.rating,
      review: values?.review || " ",
    };
    SendReview(payload)
      .then((resp) => {
        setReviewModalVisible(false);
        setSuccessModalVisible(true);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  if (jobData) {
    return (
      <Box
        sx={{
          padding: {
            xs: "20px 15px",
            sm: "20px 15px",
            md: "20px",
            lg: "30px",
            xl: "30px",
          },
        }}
        maxHeight={"90vh"}
        overflow={"auto"}
      >
        {showRatings ? (
          <RatingsAndReviewsListHos
            newUserDetails={{
              ...nurseData.userData,
              rating: nurseData?.rating,
              ratingCount: nurseData?.ratingCount,
            }}
            breadCrumb={[
              {
                title: "Hired Staff",
                route: "",
                func: () => {
                  setShowRatings(false);
                  const arr = [
                    {
                      title: "Hired Staff",
                      route: "",
                    },
                  ];
                  dispatch(setBreadcrumb(arr));
                },
              },
              {
                title:
                  nurseData?.userData?.firstName +
                  " " +
                  nurseData?.userData?.lastName,
                route: "",
              },
            ]}
            handleHideRatings={() => {
              setShowRatings(false);
              const arr = [
                {
                  title: "Hired Staff",
                  route: "",
                },
              ];
              if (jobData?.title) {
                dispatch(setBreadcrumb(arr));
              }
            }}
            handleShowUser={() => {}}
            jobData={jobData}
            type={""}
            isNotActive
          />
        ) : (
          <>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{
                  marginBottom: {
                    xs: "5px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                }}
              >
                {nurseData?.jobData?.length > 1 ? (
                  <Select
                    defaultValue={selectedJobTitle}
                    value={selectedJobTitle}
                    onChange={handleJobChange}
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                        md: "22px",
                        lg: "24px",
                        xl: "24px",
                      },
                      fontWeight: 700,
                      lineHeight: "28.8px",
                      color: "#0D2645",
                      marginBottom: {
                        xs: 0,
                        sm: 0,
                        md: "10px",
                        lg: "20px",
                        xl: "20px",
                      },
                      marginRight: "20px",
                      padding: "10px 25px 10px 0",
                      background: "#eff6f8",
                      width: "100%",
                      height: "50px",
                      textAlign: "left",
                      borderRadius: "10px",
                      "&:after": {
                        borderBottom: "none",
                      },
                      "&:before": {
                        borderBottom: "none",
                      },
                      "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "none",
                      },
                      "&.Mui-focused": {
                        border: "none",
                        outline: "none",
                      },
                    }}
                  >
                    {nurseData?.jobData?.map((i) => {
                      return (
                        <MenuItem
                          value={i.title}
                          key={i.title}
                          sx={{
                            borderBottom: "1px solid lightgrey",
                            "&.Mui-selected:hover": {
                              background: "#fff",
                            },
                            "&.Mui-selected": {
                              background: "#fff",
                            },
                          }}
                        >
                          {i.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                        md: "22px",
                        lg: "24px",
                        xl: "24px",
                      },
                      fontWeight: 700,
                      lineHeight: "28.8px",
                      color: "#0D2645",
                      marginRight: "15px",
                    }}
                  >
                    {selectedJobTitle}
                  </Typography>
                )}
                <Chip
                  sx={{
                    background: "#ecf3f6",
                    width: {
                      xs: "165px",
                      sm: "200px",
                      md: "auto",
                      lg: "auto",
                      xl: "auto",
                    },
                    marginTop: nurseData?.jobData?.length > 1 ? "7px" : "0px",
                  }}
                  label={
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "11px",
                            lg: "12px",
                            xl: "12px",
                          },
                          fontWeight: 400,
                          lineHeight: "12px",
                          textAlign: "left",
                          color: "#50647a",
                        }}
                      >
                        Start Date:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "15px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                          fontWeight: 600,
                          lineHeight: "19.2px",
                          textAlign: "left",
                          color: "#0D2645",
                        }}
                      >
                        &nbsp;
                        {jobData?.startDate
                          ? format(new Date(jobData?.startDate), "dd MMM, yyyy")
                          : ""}
                      </Typography>
                    </Box>
                  }
                  variant="filled"
                />
              </Box>
              <Box>
                {/* <img
          src={EditJobIcon}
          alt="edit job"
          onClick={() => navigate(`/hospital/edit-job/${jobData?._id}`)}
        /> */}
              </Box>
            </Box>
            <Grid container>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Job Offer:"}
                  value={`${getFormattedValue(
                    jobData?.weeklyPay +
                      jobData?.houseAllowance +
                      jobData?.mealAllowance
                  )} /wk`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    xl: "block",
                    lg: "block",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={5}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.shifts?.map((shift, idx) => {
                    return (
                      getShiftNames(shift) +
                      (jobData?.shifts?.length !== idx + 1 ? ", " : "")
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Duration:"}
                  value={`${jobData?.duration} Weeks`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    xl: "none",
                    lg: "none",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={5}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.shifts?.map((shift, idx) => {
                    return (
                      getShiftNames(shift) +
                      (jobData?.shifts?.length !== idx + 1 ? ", " : "")
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Exp. Required:"}
                  value={`${jobData?.experience} Years`}
                />
              </Grid>
            </Grid>
            <Divider
              sx={{
                margin: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "20px 0",
                  lg: "20px 0",
                  xl: "20px 0",
                },
              }}
            />
            <Box textAlign={"left"}>
              <Box
                sx={{
                  height: "calc(100vh - 380px)",
                  overflow: "auto",
                }}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <img
                    alt={""}
                    src={nurseData?.userData?.profilePicture}
                    width={132}
                    style={{ borderRadius: "25px" }}
                  />
                </Box>
                <Box marginLeft={"20px"}>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 700,
                      lineHeight: "28.8px",
                      color: "#0d2645",
                    }}
                  >
                    {nurseData?.userData?.firstName}{" "}
                    {nurseData?.userData?.lastName}
                  </Typography>
                  <Box
                    display={"flex"}
                    onClick={() => setShowRatings(true)}
                    sx={{ cursor: "pointer" }}
                  >
                    <img src={StarIcon} alt="star" />
                    <Typography
                      color="#0d2645"
                      fontSize={"14px"}
                      lineHeight={"21px"}
                      fontWeight={500}
                      marginLeft={"5px"}
                    >
                      {`${nurseData?.rating} (${nurseData?.ratingCount} ${
                        nurseData?.ratingCount > 1 ? "reviews" : "review"
                      })`}
                    </Typography>
                  </Box>
                  <Chip
                    label={"Accepted"}
                    sx={{
                      bgcolor: "#00b81d",
                      color: "#fff",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "19.2px",
                      marginTop: "5px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "50%",
                      lg: "50%",
                      xl: "45%",
                    },
                  }}
                  marginTop={"20px"}
                  justifyContent={"space-between"}
                >
                  <Chip
                    sx={{
                      height: "35px",
                      marginBottom: {
                        xs: "10px",
                        sm: "10px",
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                      marginRight: {
                        xs: "10px",
                        sm: "10px",
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                    }}
                    variant={"outlined"}
                    label={
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <img src={ClockIcon} alt={" "} />
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "19.2px",
                            color: "#0D2645",
                            paddingLeft: "5px",
                          }}
                        >
                          {nurseData?.userData?.experience} Years Experience
                        </Typography>
                      </Box>
                    }
                  />
                  <Chip
                    sx={{
                      height: "35px",
                      marginBottom: {
                        xs: "10px",
                        sm: "10px",
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                    }}
                    variant={"outlined"}
                    label={
                      <Link
                        to={`tel:${
                          nurseData?.userData?.countryCode +
                          nurseData?.userData?.phone
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <img src={CallIcon} alt={" "} />
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: "19.2px",
                              color: "#0D2645",
                              paddingLeft: "5px",
                            }}
                          >
                            +
                            {nurseData?.userData?.countryCode +
                              "-" +
                              nurseData?.userData?.phone}
                          </Typography>
                        </Box>
                      </Link>
                    }
                  />
                  <Chip
                    sx={{
                      height: "35px",
                      marginBottom: {
                        xs: "10px",
                        sm: "10px",
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                      marginRight: {
                        xs: "10px",
                        sm: "10px",
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                    }}
                    variant={"outlined"}
                    label={
                      <Link
                        to={`mailto:${nurseData?.userData?.email}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <img src={EmailIcon} alt={" "} />
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: "19.2px",
                              color: "#0D2645",
                              paddingLeft: "5px",
                            }}
                          >
                            {nurseData?.userData?.email}
                          </Typography>
                        </Box>
                      </Link>
                    }
                  />
                </Box>
                <Box margin={"20px 0"}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 400,
                      lineHeight: "21.6px",
                      color: "#0d2645",
                      marginBottom: "10px",
                    }}
                  >
                    Profile Description
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "26px",
                      color: "#808f9f",
                    }}
                  >
                    {nurseData?.userData?.bio}
                  </Typography>
                </Box>
                {nurseData?.userData?.resume && (
                  <Link to={nurseData?.userData?.resume} target="_blank">
                    <Button
                      sx={{
                        height: "55px",
                        width: {
                          xs: "100%",
                          sm: "70%",
                          md: "50%",
                          lg: "20%",
                          xl: "11%",
                        },
                        marginTop: "15px",
                        background: "#38879f",
                        color: "#fff",
                        fontWeight: 600,
                        borderRadius: "10px",
                        "&.Mui-disabled": {
                          background: "#adcdd7",
                          color: "#fff",
                        },
                        "&:hover": {
                          backgroundColor: "#38879f",
                        },
                      }}
                    >
                      View Resume
                    </Button>
                  </Link>
                )}
                {!jobData?.applicantDetails?.isCompleted && (
                  <Box textAlign={"right"}>
                    <Button
                      sx={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        height: "55px",
                        marginTop: "15px",
                        background: "#38879f",
                        color: "#fff",
                        fontWeight: 600,
                        borderRadius: "10px",
                        "&.Mui-disabled": {
                          background: "#adcdd7",
                          color: "#fff",
                        },
                        "&:hover": {
                          backgroundColor: "#38879f",
                        },
                      }}
                      onClick={() => setCompleteModalVisible(true)}
                    >
                      Mark as Completed
                    </Button>
                  </Box>
                )}
              </Box>
              {jobData?.applicantDetails?.isCompleted &&
                !jobData?.isReviewed && (
                  <Box
                    sx={{
                      paddingTop: "30px",
                    }}
                    textAlign={"right"}
                  >
                    <Button
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "50%",
                          md: "50%",
                          lg: "30%",
                          xl: "20%",
                        },
                        height: "55px",
                        marginTop: "15px",
                        background: "#38879f",
                        color: "#fff",
                        fontWeight: 600,
                        borderRadius: "10px",
                        "&.Mui-disabled": {
                          background: "#adcdd7",
                          color: "#fff",
                        },
                        "&:hover": {
                          backgroundColor: "#38879f",
                        },
                      }}
                      onClick={() => setReviewModalVisible(true)}
                    >
                      Write a Review
                    </Button>
                  </Box>
                )}
              <RatingModal
                open={reviewModalVisible}
                handleClose={() => setReviewModalVisible(false)}
                handleSubmit={handleReviewSubmit}
              />
              <CommonModal
                title="Are you sure you want to
    complete this Job?"
                open={completeModalVisible}
                icon={ConfirmIcon}
                onSubmit={handleCompleteJob}
                onClose={() => setCompleteModalVisible(false)}
                button1Text={"Yes"}
                button2Text={"No"}
              />
              <CommonModal
                title="Review submitted
        successfully"
                open={successModalVisible}
                icon={TickCardIcon}
                onClose={() => setSuccessModalVisible(false)}
                isSingleButton
                singleBtnText={"Done"}
              />
            </Box>
          </>
        )}
      </Box>
    );
  }
}
